body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #272727;
}

/* TODO: Fix bug on About page where animation not functional. */
.slider:hover li {
    animation-play-state: paused !important;
}

@keyframes cycle {
    0% {
        right: 50%;
    }

    4% {
        right: 50%;
    }

    16% {
        right: 50%;
        opacity: 1;
        z-index: 0;
    }

    20% {
        right: 100%;
        opacity: 0;
        z-index: 0;
    }

    21% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    50% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    92% {
        right: 0;
        opacity: 0;
        z-index: 0;
    }

    96% {
        right: 0;
        opacity: 0;
    }

    100% {
        right: 50%;
        opacity: 1;
    }
}

@keyframes cycle2 {
    0% {
        right: 0;
        opacity: 0;
    }

    16% {
        right: 0;
        opacity: 0;
    }

    20% {
        right: 50%;
        opacity: 1;
    }

    24% {
        right: 50%;
        opacity: 1;
    }

    36% {
        right: 50%;
        opacity: 1;
        z-index: 0;
    }

    40% {
        right: 100%;
        opacity: 0;
        z-index: 0;
    }

    41% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    100% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }
}

@keyframes cycle3 {
    0% {
        right: 0;
        opacity: 0;
    }

    36% {
        right: 0;
        opacity: 0;
    }

    40% {
        right: 50%;
        opacity: 1;
    }

    44% {
        right: 50%;
        opacity: 1;
    }

    56% {
        right: 50%;
        opacity: 1;
        z-index: 0;
    }

    60% {
        right: 100%;
        opacity: 0;
        z-index: 0;
    }

    61% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    100% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }
}

@keyframes cycle4 {
    0% {
        right: 0;
        opacity: 0;
    }

    56% {
        right: 0;
        opacity: 0;
    }

    60% {
        right: 50%;
        opacity: 1;
    }

    64% {
        right: 50%;
        opacity: 1;
    }

    76% {
        right: 50%;
        opacity: 1;
        z-index: 0;
    }

    80% {
        right: 100%;
        opacity: 0;
        z-index: 0;
    }

    81% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    100% {
        right: 0;
        opacity: 0;
        z-index: -1;
    }
}

@keyframes cycle5 {
    0% {
        right: 0;
        opacity: 0;
    }

    76% {
        right: 0;
        opacity: 0;
    }

    80% {
        right: 50%;
        opacity: 1;
    }

    84% {
        right: 50%;
        opacity: 1;
    }

    96% {
        right: 50%;
        opacity: 1;
        z-index: 0;
    }

    100% {
        right: 100%;
        opacity: 0;
        z-index: 0;
    }
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}